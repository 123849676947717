import React, { useState } from "react";
import Button from "../../components/button";
import Stars from "../../components/commons/stars";
import Layout from "../../components/layout/layout";
import { parseStory } from "../../components/utils";
import mockImg from "../../resources/images/calco-family.png";
import arrL from "../../resources/images/icon_arrow_big_left.svg";
import arrR from "../../resources/images/icon_arrow_big_right.svg";

const ServiziPage = ({ data }) => {
  const prodotti = [
    {
      tit: "Restauro Professionale",
      sot: "Rendi la tua scultura impeccabile",
      avgScore: 8,
      numReviews: 12,
      desc: "La tua Scultura non ha solo un grande valore artistico, ma rappresenta per te un ricordo importantissimo che non può rimanere incompiuto o imperfetto. Ecco alcuni preziosi consigli per rimediare velocemente ai difetti e rendere la tua Scultura perfetta.",
      price: "39.90",
      imgs: [<img src={mockImg} alt="" />, <img src={mockImg} alt="" />],
    },
    {
      tit: "Rifinitura Professionale",
      sot: "Rendi la tua scultura impeccabile",
      avgScore: 8,
      numReviews: 12,
      desc: "La tua Scultura non ha solo un grande valore artistico, ma rappresenta per te un ricordo importantissimo che non può rimanere incompiuto o imperfetto. Ecco alcuni preziosi consigli per rimediare velocemente ai difetti e rendere la tua Scultura perfetta.",
      price: "39.90",
      imgs: [<img src={mockImg} alt="" />, <img src={mockImg} alt="" />],
    },
  ];

  const servizi = parseStory(data.head).content;

  return (
    <Layout>
      <div className="ci-cont">
        <div className="mt-16 flex flex-wrap gap-4 md:gap-0">
          <div className="w-full md:w-1/2">
            <h2 className="ci-h2">{servizi.titolo}</h2>
            <p className="mt-6">{servizi.desc}</p>
          </div>
          <img
            src={servizi.img.filename}
            alt={servizi?.img?.alt ?? ""}
            className="md:pl-20 w-full md:w-1/2 order-first md:order-none"
          />
        </div>
        {prodotti.map((s) => (
          <ServizioCard s={s} key={s.tit} />
        ))}
      </div>
    </Layout>
  );
};

const ServizioCard = ({ s }) => {
  const [activeImg, setActiveImg] = useState(0);
  return (
    <div className="my-12 flex gap-8 flex-wrap md:flex-nowrap">
      <div className="md:w-1/2 flex-shrink-0">
        <div className="flex items-center">
          <button
            onClick={() => {
              setActiveImg((o) => Math.max(0, o - 1));
            }}
          >
            <img src={arrL} alt="immagine precedente" className="mx-2 w-12" />
          </button>
          <div className="flex-grow overflow-hidden">
            <div
              style={{
                width: s.imgs.length + "00%",
                marginLeft: `-${activeImg}00%`,
              }}
              className="transition-all duration-150 flex   "
            >
              {s.imgs.map((img, i) => (
                <div style={{ width: `${100 / s.imgs.length}%` }} key={i}>
                  {img}
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => {
              setActiveImg((o) => Math.min(s.imgs.length - 1, o + 1));
            }}
          >
            <img src={arrR} alt="immagine successiva" className="mx-2 w-12" />
          </button>
        </div>
        <div className="mt-5 flex justify-center gap-1.5">
          {[...Array(s.imgs.length).keys()].map((i) => (
            <div
              key={i}
              className={`w-2.5 h-2.5 rounded-full border-ci-gray border ${
                i === activeImg ? "bg-ci-gray" : ""
              }`}
            />
          ))}
        </div>
      </div>
      <div className="flex-grow pt-10">
        <h3 className="text-2xl font-bold">{s.tit}</h3>
        <p className="font-bold mt-4">{s.sot}</p>
        <div className="mt-6 flex gap-2.5 items-center">
          <Stars rating={s.avgScore} />
          <p>{s.numReviews} Recensioni</p>
        </div>
        <p className="mt-8">{s.desc}</p>
        <p className="mt-8 flex items-baseline gap-2.5">
          da <span className="font-bold text-2xl">€{s.price}</span>
        </p>
        <div className="mt-10">
          <Button link="/" className="uppercase">
            Scopri di più
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServiziPage;
