import { graphql } from "gatsby";
import React from "react";
import ServiziPage from "../../components/pages/servizi";

const Servizi = (props) => <ServiziPage {...props} />;

export const query = graphql`
  query ServiziQueryEn {
    head: storyblokEntry(lang: { eq: "en" }, slug: { eq: "servizi-copy" }) {
      content
    }
  }
`;

export default Servizi;
